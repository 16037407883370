<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="6"
    >
      <v-data-table
        :headers="headers"
        :items="ParamsAGR"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              Paramètre AGR
            </v-toolbar-title>
            <v-spacer />
            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on, attrs}">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Nouveau
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="ParamsAGREdit.libelle_parametrage_agrs"
                          label="Libellé"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darke-1"
                    @click="close"
                  >
                    Fermer
                  </v-btn>
                  <v-btn
                    color="blue darke-1"
                    @click="saveParamAGR"
                  >
                    Enregistrer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="text-h5">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{item}">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon
            small
            @click="navigateTo(item)"
          >
            mdi-tune-variant
          </v-icon>
        </template>
        <template v-slot:item.logo_organisations="{item}">
          <v-img
            :lazy-src="item.logo_organisations"
            max-height="150"
            max-width="250"
            :src="item.logo_organisations"
          />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import parametres from '@/services/parametre-agr.js'
  export default {
    data () {
      return {
        dialog: false,
        dialogDelete: false,
        headers: [
          { text: 'Actions', value: 'actions', sortable: false },
          {
            text: 'Libellé',
            value: 'libelle_parametrage_agrs',
          },
        ],
        ParamsAGR: [],
        editedIndex: -1,
        ParamsAGREdit: {
          id: '',
          libelle_parametrage_agrs: '',
        },
        ParamAGR: {
          libelle_parametrage_agrs: '',
        },
      }
    },
    mounted () {
      console.log(this.editedIndex)
      parametres.getParamsAGR().then(response => {
        this.ParamsAGR = response.data
      })
    },
    computed: {
      ...mapGetters(['getThemeMode']),
      formTitle () {
        return this.editedIndex === -1 ? 'Ajouter un profil' : 'Modifier un profil'
      },
    },
    methods: {
      ...mapActions(['changeThemeLayout']),
      saveParamAGR () {
        if (this.editedIndex > -1) {
          parametres.updateParamsAGR(this.ParamsAGREdit).then(response => {
            console.log(response.data)
            parametres.getParamsAGR().then(response => {
              this.ParamsAGR = response.data
            })
            this.dialog = false
          }).catch(err => {
            console.log(err.data)
          })
        } else {
          parametres.createParamsAGR(this.ParamsAGREdit).then(response => {
            console.log(response.data)
            parametres.getParamsAGR().then(response => {
              this.ParamsAGR = response.data
            })
            this.dialog = false
          }).catch(err => {
            console.log(err.data)
          })
        }
        this.close()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.ParamsAGREdit = Object.assign({}, this.ParamAGR)
          this.editedIndex = -1
        })
      },
      editItem (item) {
        this.editedIndex = this.ParamsAGR.indexOf(item)
        this.ParamsAGREdit = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.ParamsAGR.indexOf(item)
        this.ParamsAGREdit = Object.assign({}, item)
        this.dialogDelete = true
      },
      navigateTo (item) {
        this.$router.push({
          name: 'ligne-agr.index',
          params: { AGR: item.id },
        })
      },
      deleteItemConfirm () {
        parametres.deleteParamsAGR(this.ParamsAGREdit).then(response => {
          parametres.getParamsAGR().then(response => {
            this.ParamsAGR = response.data
          })
        }).catch(err => {
          console.log(err.data)
        })
        this.closeDelete()
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.ParamsAGREdit = Object.assign({}, this.ParamAGR)
          this.editedIndex = -1
        })
      },
    },
  }
</script>

<style scoped>

</style>
